.home {
    background-color: rgba(0,34,51);
    color: white;
    background-image: url(../static/bg.svg);
    background-repeat: no-repeat;
}
.loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .video-preview {
    width: 100%;
    max-width: 500px; /* Adjust the max-width as needed */
    margin: 0 auto; /* Center the video */
  }
  