@tailwind base;
@tailwind components;
@tailwind utilities;


* {
    background-color: rgba(0,34,51);
    color: white;

}
